import { FetchParams, Middleware, RequestContext } from "../runtime";

/**
 * Возвращает сигнал, который срабатывает, когда срабатывает любой из сигналов в signals.
 */
export function combineAbortSignals(signals: AbortSignal[]): AbortSignal {
  const controller = new AbortController();

  function onAbort() {
    controller.abort();

    // Cleanup
    for (const signal of signals) {
      signal.removeEventListener('abort', onAbort);
    }
  }

  for (const signal of signals) {
    if (signal.aborted) {
      onAbort();
      break;
    }
    signal.addEventListener('abort', onAbort);
  }

  return controller.signal;

}

export default class TimeoutMiddleware implements Middleware {
  timeout: number;  // в секундах

  constructor(timeout: number) {
    this.timeout = timeout;
  }

  async pre(context: RequestContext): Promise<FetchParams | void> {
    if (window.AbortController) {
      let controller = new AbortController();
      setTimeout(() => controller.abort(), this.timeout * 1000);

      let signal = controller.signal;
      if (context.init.signal) {
        signal = combineAbortSignals([context.init.signal, signal])
      }

      return {
        url: context.url,
        init: {
          ...context.init,
          signal,
        },
      };
    } else {
      console.warn("Браузер не поддерживает таумаут для fetch. Таймаут не будет задан.");
    }
  }
}
