/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ActivationRequest
 */
export interface ActivationRequest {
    /**
     *
     * @type {number}
     * @memberof ActivationRequest
     */
    readonly id?: number;
    /**
     *
     * @type {number}
     * @memberof ActivationRequest
     */
    number: number;
    /**
     *
     * @type {string}
     * @memberof ActivationRequest
     */
    sellerContactNumber: string;
    /**
     *
     * @type {string}
     * @memberof ActivationRequest
     */
    clientContactNumber?: string;
    /**
     *
     * @type {string}
     * @memberof ActivationRequest
     */
    account: string;
    /**
     *
     * @type {string}
     * @memberof ActivationRequest
     */
    tariffName: string;
    /**
     *
     * @type {string}
     * @memberof ActivationRequest
     */
    simNumber: string;
}

/**
 * Check if a given object implements the ActivationRequest interface.
 */
export function instanceOfActivationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "sellerContactNumber" in value;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "tariffName" in value;
    isInstance = isInstance && "simNumber" in value;

    return isInstance;
}

export function ActivationRequestFromJSON(json: any): ActivationRequest {
    return ActivationRequestFromJSONTyped(json, false);
}

export function ActivationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': json['number'],
        'sellerContactNumber': json['seller_contact_number'],
        'clientContactNumber': !exists(json, 'client_contact_number') ? undefined : json['client_contact_number'],
        'account': json['account'],
        'tariffName': json['tariff_name'],
        'simNumber': json['sim_number'],
    };
}

export function ActivationRequestToJSON(value?: ActivationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'number': value.number,
        'seller_contact_number': value.sellerContactNumber,
        'client_contact_number': value.clientContactNumber,
        'account': value.account,
        'tariff_name': value.tariffName,
        'sim_number': value.simNumber,
    };
}
