/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GenericError
 */
export interface GenericError {
    /**
     * Error details
     * @type {string}
     * @memberof GenericError
     */
    detail: string;
    /**
     * Error code
     * @type {string}
     * @memberof GenericError
     */
    code?: string;
}

/**
 * Check if a given object implements the GenericError interface.
 */
export function instanceOfGenericError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "detail" in value;

    return isInstance;
}

export function GenericErrorFromJSON(json: any): GenericError {
    return GenericErrorFromJSONTyped(json, false);
}

export function GenericErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'detail': json['detail'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function GenericErrorToJSON(value?: GenericError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'detail': value.detail,
        'code': value.code,
    };
}
