import { ActionIcon, CSSObject, Flex, MediaQuery } from "@mantine/core";
import { IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import { useSmallerThan } from "../hooks";

interface Props {
  sortAsc: boolean,
  onChangeSort: () => void,
}

function NumberGridHeader(props: Props) {
  const hide: CSSObject = {
    display: "none",
  };

  const sortIcon = props.sortAsc
    ? <IconSortDescending size={16}/>
    : <IconSortAscending size={16}/>;

  const smallerThanSm = useSmallerThan("sm");
  let colWidths: [string, string, string, string];
  if (smallerThanSm) {
    colWidths = ["41px", "60%", "40%", "0"];
  } else {
    colWidths = ["41px", "35%", "25%", "40%"];
  }

  return (
    <thead>
      <tr>
        <th style={{ width: colWidths[0] }}></th>
        <th style={{ width: colWidths[1] }}>Номер</th>
        <th style={{ width: colWidths[2] }}>
          <Flex direction="row" wrap="nowrap" align="center">
            <div style={{ flexGrow: "1" }}>Аренда</div>
            <ActionIcon onClick={props.onChangeSort} data-cy="rent-price-sort-button">
              {sortIcon}
            </ActionIcon>
          </Flex>
        </th>
        <MediaQuery smallerThan="sm" styles={hide}>
          <th style={{ width: colWidths[3] }}>Теги</th>
        </MediaQuery>
      </tr>
    </thead>
  );
}

export default NumberGridHeader;
