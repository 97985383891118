import { Image, Tooltip } from "@mantine/core";
import { Provider } from "../client";

interface Props {
  provider: Provider,
  "data-cy"?: string,
}

function ProviderIcon(props: Props) {
  return (
    <Tooltip label={props.provider.displayName}>
      <Image
        width={16}
        height={16}
        src={props.provider.icon}
        data-cy={props["data-cy"]}
        fit="contain"
        style={{ display: "inline-block", position: "relative", bottom: "calc(-8px + 0.45em)" }}
      />
    </Tooltip>
  );
}

export default ProviderIcon;
