export interface CommonSelectItem<T> {
  value: string,
  label: string,
  object: T,
}

export function toSelectItems<T>(objects: T[], valueKey: keyof T, labelKey: keyof T): CommonSelectItem<T>[] {
  return objects.map(object => ({
    value: String(object[valueKey]),
    label: String(object[labelKey]),
    object,
  }));
}

export function numberToDigits(number: string): string {
  return number.replace(/\D/g, "");
}
