/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Provider } from './Provider';
import {
    ProviderFromJSON,
    ProviderFromJSONTyped,
    ProviderToJSON,
} from './Provider';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 *
 * @export
 * @interface Number
 */
export interface Number {
    /**
     *
     * @type {number}
     * @memberof Number
     */
    readonly id?: number;
    /**
     *
     * @type {string}
     * @memberof Number
     */
    number: string;
    /**
     *
     * @type {string}
     * @memberof Number
     */
    number10: string;
    /**
     *
     * @type {number}
     * @memberof Number
     */
    rentPrice: number;
    /**
     *
     * @type {boolean}
     * @memberof Number
     */
    landline?: boolean;
    /**
     *
     * @type {Provider}
     * @memberof Number
     */
    provider: Provider;
    /**
     *
     * @type {Region}
     * @memberof Number
     */
    region: Region;
    /**
     *
     * @type {Array<Tag>}
     * @memberof Number
     */
    readonly tags?: Array<Tag>;
}

/**
 * Check if a given object implements the Number interface.
 */
export function instanceOfNumber(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "number10" in value;
    isInstance = isInstance && "rentPrice" in value;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "region" in value;

    return isInstance;
}

export function NumberFromJSON(json: any): Number {
    return NumberFromJSONTyped(json, false);
}

export function NumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Number {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': json['number'],
        'number10': json['number10'],
        'rentPrice': json['rent_price'],
        'landline': !exists(json, 'landline') ? undefined : json['landline'],
        'provider': ProviderFromJSON(json['provider']),
        'region': RegionFromJSON(json['region']),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function NumberToJSON(value?: Number | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'number': value.number,
        'number10': value.number10,
        'rent_price': value.rentPrice,
        'landline': value.landline,
        'provider': ProviderToJSON(value.provider),
        'region': RegionToJSON(value.region),
    };
}
