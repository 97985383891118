import ErrorMiddleware, { ApiError } from "./client/middleware/ErrorMiddleware";
import { showNotification } from "@mantine/notifications";
import { UseFormReturnType } from '@mantine/form';
import TimeoutMiddleware from "./client/middleware/TimeoutMiddleware";
import { ActivationRequestApi, Configuration, HTTPHeaders, NumberApi, ProviderApi, RegionApi, TagApi } from "./client";
import { getCookie } from "./utils/cookies";

export type ApiRequestState = 'idle' | 'loading' | 'succeeded' | 'failed';

export function getApi() {
  const headers: HTTPHeaders = {};

  const csrfToken = getCookie("csrftoken");
  if (csrfToken) {
    headers["X-CSRFToken"] = csrfToken;
  }

  const configuration = new Configuration({
    headers,
    middleware: [
      new ErrorMiddleware(),
      new TimeoutMiddleware(window.FRONTEND_API_CLIENT_TIMEOUT || 30),
    ],
  });
  return {
    tag: new TagApi(configuration),
    provider: new ProviderApi(configuration),
    region: new RegionApi(configuration),
    number: new NumberApi(configuration),
    activationRequest: new ActivationRequestApi(configuration),
  };
}

/**
 * Отображает сообщение об ошибке в нотификации.
 * @param exc Исключение, выпавшее при выполнении запроса API.
 */
export function handleApiError(exc: any) {
  if (exc instanceof ApiError) {
    if (exc.body.type === "GenericError") {
      showNotification({
        color: "red",
        title: "Ошибка",
        message: exc.body.value.detail,
      });
      return;
    } else if (exc.body.type === "ValidationError") {
      let errorMessage = "";
      for (const key in exc.body.value) {
        if (!Object.prototype.hasOwnProperty.call(exc.body.value, key)) {
          continue;
        }

        let errors = exc.body.value[key];
        if (errors) {
          errorMessage += " " + exc.body.value[key].join(" ");
        }
      }

      showNotification({
        color: "red",
        title: "Ошибка",
        message: errorMessage,
      });
      return;
    }
  }

  showNotification({
    color: "red",
    title: "Ошибка",
    message: <>Не удалось выполнить запрос.<br/>Пожалуйста, попробуйте еще раз позже.</>,
  });
}

function apiFieldToFormField(apiField: string): string {
  let words = apiField.split("_")
  // Переводит первые буквы каждого слова в верхний регистр, кроме первого
  words = words.map((part, ix) => ix === 0 ? part : part[0].toUpperCase() + part.slice(1))
  return words.join("");
}

/**
 * Отображает сообщение об ошибке в нотификации. Ошибки валидации полей будут отображены в форме.
 * @param exc Исключение, выпавшее при выполнении запроса API.
 */
 export function handleApiFormError(exc: any, form: UseFormReturnType<any>) {
  if (!(exc instanceof ApiError)) {
    handleApiError(exc);
    return;
  }

  if (exc.body.type !== "ValidationError") {
    handleApiError(exc);
    return;
  }

  let fieldErrors: Record<string, React.ReactNode> = {};
  for (const key in exc.body.value) {
    if (!Object.prototype.hasOwnProperty.call(exc.body.value, key)) {
      continue;
    }

    if (key === "nonFieldErrors") {
      continue;
    }

    if (key === "__all__") {
      continue;
    }

    const keyErrors = exc.body.value[key];
    let children: React.ReactNode[] = [];
    keyErrors.forEach((err: string) => {
      children.push(err);
      children.push(<br/>);
    })
    // Удаляем последний <br/>
    children = children.slice(0, children.length - 1);
    fieldErrors[apiFieldToFormField(key)] = <div>{children}</div>;
  }
  form.setErrors(fieldErrors);

  if (exc.body.value.nonFieldErrors) {
    showNotification({
      color: "red",
      title: "Ошибка",
      message: exc.body.value.nonFieldErrors.join(" "),
    });
  }

  if (exc.body.value.__all__) {
    showNotification({
      color: "red",
      title: "Ошибка",
      message: exc.body.value.__all__.join(" "),
    });
  }
}
