import { useMantineTheme } from "@mantine/core";
import { Number } from "../client";
import NumberGridRow from "./NumberGridRow";

interface Props {
  page: number,
  showHeader: boolean,
  numbers: Number[],
  selectedNumberId: number | null,
  onSelectNumber: (number: Number) => void,
}

function NumberGridPage(props: Props) {
  const theme = useMantineTheme();

  return (
    <tbody>
      {props.showHeader && (
        <tr>
          <td colSpan={4} style={{ textAlign: "center", color: theme.colors.gray[6], borderTop: `1px solid ${theme.colors.gray[5]}` }}>
            Страница <span data-cy="numbers-grid-page-number">{props.page}</span>
          </td>
        </tr>
      )}
      {props.numbers.map(number => (
        <NumberGridRow
          key={number.id}
          number={number}
          selected={props.selectedNumberId === number.id}
          onSelect={() => props.onSelectNumber(number)}
        />
      ))}
    </tbody>
  );
}

export default NumberGridPage;
