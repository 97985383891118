import { Button, LoadingOverlay, Modal, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { numberToDigits } from "../utils/format";
import { useApi, useApiRequest } from "../hooks";
import { Number } from "../client";
import { showNotification } from "@mantine/notifications";
import MaskInput from "./MaskInput";
import { useCallback } from "react";

interface Props {
  opened: boolean,
  number: Number,
  onClose: () => void,
}

interface FormValues {
  tariffName: string,
  account: string,
  simNumber: string,
  sellerContactNumber: string,
  clientContactNumber: string,
}

function ActivationRequestModal(props: Props) {
  const form = useForm<FormValues>({
    initialValues: {
      tariffName: "",
      account: "",
      simNumber: "",
      sellerContactNumber: "",
      clientContactNumber: "",
    },
    validate: {
      tariffName: value => {
        if (!value) {
          return "Введите название тарифа.";
        }
      },
      account: value => {
        if (!value) {
          return "Введите ID партнера.";
        }
      },
      simNumber: value => {
        if (!value) {
          return "Введите серийный номер симкарты.";
        }
      },
      sellerContactNumber: value => {
        if (!value) {
          return "Введите ваш телефон.";
        }
        if (numberToDigits(value).length !== 11) {
          return "Номер должен содержать 11 цифр.";
        }
      },
      clientContactNumber: value => {
        if (value && numberToDigits(value).length !== 11) {
          return "Номер должен содержать 11 цифр.";
        }
      },
    }
  });

  const handleClose = useCallback(() => {
    form.reset();
    props.onClose();
  }, [form, props])

  const api = useApi();

  const [create, createState] = useApiRequest(async (values: FormValues) => {
    await api.activationRequest.activationRequestCreate({
      data: {
        number: props.number.id || 0,
        sellerContactNumber: values.sellerContactNumber,
        clientContactNumber: values.clientContactNumber || undefined,
        account: values.account,
        tariffName: values.tariffName,
        simNumber: values.simNumber,
      },
    });
    showNotification({
      color: "green",
      title: "Успешно",
      message: "Заявка успешно отправлена.",
    });
    handleClose();
  }, { form });

  return (
    <Modal
      opened={props.opened}
      onClose={handleClose}
      closeOnEscape={false}
      closeOnClickOutside={false}
      title="Отправить заявку на активацию"
      data-cy="activation-request-modal"
    >
      <form onSubmit={form.onSubmit(values => create(values))} style={{ position: "relative" }} data-cy="activation-request-form">
        <LoadingOverlay visible={createState === "loading"} overlayBlur={2} sx={{ margin: -8 }} />

        <Stack>
          <TextInput
            label="Выбранный номер"
            value={props.number.number}
            disabled
            data-cy="number-field"
          />
          <TextInput
            label="Название тарифа"
            required
            data-cy="tariff-name-field"
            {...form.getInputProps('tariffName')}
          />
          <TextInput
            label="ID партнера"
            required
            data-cy="partner-id-field"
            {...form.getInputProps('account')}
          />
          <TextInput
            label="Серийный номер симкарты"
            required
            data-cy="sim-number-field"
            {...form.getInputProps('simNumber')}
          />
          <MaskInput
            label="Ваш контактный телефон"
            required
            mask="+7 (999) 999-99-99"
            maskChar=""
            placeholder="+7 (___) ___-__-__"
            data-cy="seller-contact-number-field"
            {...form.getInputProps('sellerContactNumber')}
          />
          <MaskInput
            label="Контактный телефон покупателя"
            mask="+7 (999) 999-99-99"
            maskChar=""
            placeholder="+7 (___) ___-__-__"
            data-cy="client-contact-number-field"
            {...form.getInputProps('clientContactNumber')}
          />

          <Button type="submit" mt={12} data-cy="submit-button">Отправить</Button>
        </Stack>
      </form>
    </Modal>
  );
}

export default ActivationRequestModal;
