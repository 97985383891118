import * as Sentry from "@sentry/react";

/**
 * Содержит ли событие "мусорное исключение" - т.е. исключение,
 * содержащее только фреймы, ссылающееся на неизвестные файлы.
 * Такие исключения выбрасываются чужими скриптами, не связанными с нашим кодом.
 * Идея взята с: https://github.com/getsentry/sentry-javascript/issues/3977
 */
function isJunkException(event: Sentry.Event): boolean {
  if (!event.exception || !event.exception.values) {
    return false;
  }

  if (event.exception.values.length < 1) {
    return false;
  }

  let exception = event.exception.values[0];
  if (!exception.stacktrace) {
    return false;
  }

  let frames = exception.stacktrace.frames;
  if (!frames) {
    return false;
  }

  for (let i = 0; i < frames.length; i++) {
    const frame = frames[i];
    // Если хотя бы один фрейм стектрейса задан на что-то полезное - исключение не мусорное.
    if (
      frame.filename &&
      frame.filename !== "<anonymous>" &&
      frame.filename !== "[native code]"
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Является ли событие неправильной обработкой ошибки загрузки внешнего скрипта в промисе.
 * Пример кода, генерирующего такое событие:
 *
 *   new Promise((resolve, reject) => {
 *     let s = document.createElement("script");
 *     s.src = "http://example.com/fails-to-load.js";
 *     s.onload = resolve;
 *     s.onerror = reject;
 *     // Правильная обработка:
 *     // s.onerror = (event) => { reject(new Error("fail")) };
 *     document.head.appendChild(s);
 *   });
 *
 * В нашем коде таких ошибок нет.
 */
function isScriptErrorEventRejection(event: Sentry.Event): boolean {
  if (!event.exception || !event.exception.values) {
    return false;
  }

  if (event.exception.values.length < 1) {
    return false;
  }

  let exception = event.exception.values[0];
  if (exception.value && /Non-Error promise rejection captured with keys/.test(exception.value)) {
    let serialized: any = event.extra ? event.extra.__serialized__ : null;
    if (serialized && /head > script/.test(serialized.target) && serialized.type === "error") {
      return true;
    }
  }
  return false;
}

export function initSentry() {
  if (window.SENTRY_DSN) {
    Sentry.init({
      dsn: window.SENTRY_DSN,
      environment: window.SENTRY_ENVIRONMENT,
      release: window.SENTRY_RELEASE,
      beforeSend: function(event, hint) {
        if (isJunkException(event)) {
          return null;
        }

        if (isScriptErrorEventRejection(event)) {
          return null;
        }

        return event;
      },
      denyUrls: [
        // Ошибки NS_ERROR_NOT_INITIALIZED из неизвестного скрипта в Firefox
        /injectedScript/,
        /widgets\.mango-office\.ru/
      ],
      normalizeDepth: 10,
    });
  }
}
