import { ValidationError, GenericError, GenericErrorFromJSON, ValidationErrorFromJSON } from "../models";
import { Middleware, ResponseContext } from "../runtime";

export interface ValidationErrorBody {
  type: "ValidationError",
  value: ValidationError,
}

export interface GenericErrorBody {
  type: "GenericError",
  value: GenericError,
}

export type ApiErrorBody = ValidationErrorBody | GenericErrorBody;

export class ApiError extends Error {
  body: ApiErrorBody;
  context: ResponseContext;

  constructor(body: ApiErrorBody, context: ResponseContext) {
    super(JSON.stringify(body.value));
    this.body = body;
    this.context = context;
  }
}

export default class ErrorMiddleware implements Middleware {
  async post(context: ResponseContext): Promise<Response | void> {
    if (context.response.status >= 400 && context.response.status <= 599) {
      let bodyValue = await context.response.json();

      let body: ApiErrorBody;
      if (bodyValue.hasOwnProperty("detail") && (typeof bodyValue.detail) === "string") {
        body = {
          type: "GenericError",
          value: GenericErrorFromJSON(bodyValue),
        };
      } else {
        body = {
          type: "ValidationError",
          value: ValidationErrorFromJSON(bodyValue),
        };
      }

      throw new ApiError(body, context);
    }
  }
}
