import { Box, Flex } from "@mantine/core";
import { forwardRef } from "react";
import { Provider } from "../client";
import ProviderIcon from "./ProviderIcon";

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string,
  object: Provider,
}

const ProviderSelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, object, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      <Flex align="center">
        {object.icon &&
          <Box mr={10}>
            <ProviderIcon provider={object}/>
          </Box>
        }
        <div>{label}</div>
      </Flex>
    </div>
  );
});

export default ProviderSelectItem;
