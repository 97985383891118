import { Table } from "@mantine/core";
import { useEffect, useRef } from "react";
import { Number } from "../client";
import NumberGridHeader from "./NumberGridHeader";
import NumberGridLoader from "./NumberGridLoader";
import NumberGridPage from "./NumberGridPage";

function getInView(element: HTMLElement, offset: number): boolean {
  const rect = element.getBoundingClientRect();
  return rect.top - offset < window.innerHeight;
}

interface Props {
  numberPages: Number[][],
  hasMore: boolean,
  loadMoreOffest: number,
  onLoadMore: () => void,
  sortAsc: boolean,
  onChangeSort: () => void,
  selectedNumberId: number | null,
  onSelectNumber: (number: Number) => void,
}

function NumberGrid(props: Props) {
  const loaderRef = useRef<HTMLTableSectionElement | null>(null);

  const checkLoaderVisible = () => {
    if(loaderRef.current && getInView(loaderRef.current, props.loadMoreOffest)) {
      props.onLoadMore();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => checkLoaderVisible(), 100);
    return () => clearInterval(interval);
  });

  return (
    <Table striped withColumnBorders highlightOnHover style={{ tableLayout: "fixed" }} styles={{ td: { boxSizing: "border-box" } }}>
      <NumberGridHeader
        sortAsc={props.sortAsc}
        onChangeSort={props.onChangeSort}
      />
      {props.numberPages.map((numbers, index) => (
        <NumberGridPage
          key={index}
          page={index + 1}
          showHeader={index > 0}
          numbers={numbers}
          selectedNumberId={props.selectedNumberId}
          onSelectNumber={props.onSelectNumber}
        />
      ))}
      {props.hasMore && <NumberGridLoader ref={loaderRef}/>}
    </Table>
  );
}

export default NumberGrid;
