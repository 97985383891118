import { Input } from "@mantine/core";
import { useId } from "@mantine/hooks";
import InputMask from 'react-input-mask';

interface Props {
  type?: string,
  label?: string,
  value?: string | number | readonly string[] | undefined,
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
  error?: React.ReactNode,
  placeholder?: string,
  mask: string,
  maskChar?: string,
  required?: boolean,
  "data-cy"?: string,
}

function MaskInput(props: Props) {
  let id = useId();

  return (
    <Input.Wrapper id={id} label={props.label} error={props.error} required={props.required}>
      <Input
        component={InputMask}
        required={props.required}
        type={props.type}
        mask={props.mask}
        maskChar={props.maskChar}
        id={id}
        placeholder={props.placeholder}
        data-cy={props["data-cy"]}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
      />
    </Input.Wrapper>
  )
}
export default MaskInput;
