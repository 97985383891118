/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    [key: string]: Array<string> | any;
    /**
     * List of validation errors not related to any field
     * @type {Array<string>}
     * @memberof ValidationError
     */
    nonFieldErrors?: Array<string>;
}

/**
 * Check if a given object implements the ValidationError interface.
 */
export function instanceOfValidationError(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationErrorFromJSON(json: any): ValidationError {
    return ValidationErrorFromJSONTyped(json, false);
}

export function ValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

            ...json,
        'nonFieldErrors': !exists(json, 'non_field_errors') ? undefined : json['non_field_errors'],
    };
}

export function ValidationErrorToJSON(value?: ValidationError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

            ...value,
        'non_field_errors': value.nonFieldErrors,
    };
}
