import { CSSObject, Group, MantineTheme, MediaQuery, Radio, Tooltip, useMantineTheme } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";
import { Number, Tag } from "../client";
import { MOSCOW_NAME } from "../consts";
import ProviderIcon from "./ProviderIcon";

interface Props {
  number: Number,
  selected: boolean,
  onSelect: () => void,
}

function formatTags(tags: Tag[] | undefined): React.ReactNode {
  return <span data-cy="number-tags">{tags?.map(t => t.name).sort().join(", ") || ""}</span>;
}

function getRegionalIcon(theme: MantineTheme, number: Number): React.ReactNode {
  if (number.region.name === MOSCOW_NAME) {
    return null;
  }

  return (
    <Tooltip label={number.region.displayName}>
      <IconWorld
        size={16}
        style={{
          position: "relative",
          bottom: "calc(-8px + 0.45em)",
        }}
        color={theme.colors.gray[6]}
        data-cy="number-regional-icon"
      />
    </Tooltip>
  );
}

function NumberGridRow(props: Props) {
  const hide: CSSObject = {
    display: "none",
  };

  const theme = useMantineTheme();

  return (
    <tr onClick={props.onSelect} data-cy="number-grid-row" data-id={props.number.id}>
      <td>
        <Radio
          checked={props.selected}
          onChange={props.onSelect}
        />
      </td>
      <td>
        <Group align="flex-start" spacing={4} noWrap>
          <ProviderIcon provider={props.number.provider} data-cy="number-provider-icon"/>
          <div>
            <div>
              <span data-cy="number">{props.number.number}</span>{" "}
              {getRegionalIcon(theme, props.number)}
            </div>
            <MediaQuery largerThan="sm" styles={hide}>
              <div style={{ color: theme.colors.gray[6] }}>
                {formatTags(props.number.tags)}
              </div>
            </MediaQuery>
          </div>
        </Group>
      </td>
      <td style={{ verticalAlign: "top" }}>
        <div style={{ whiteSpace: "nowrap" }}>
          <span data-cy="number-rent-price">{props.number.rentPrice}</span>₽/мес.
        </div>
      </td>
      <MediaQuery smallerThan="sm" styles={hide}>
        <td>
          {formatTags(props.number.tags)}
        </td>
      </MediaQuery>
    </tr>
  );
}

export default NumberGridRow;
