import { Loader, useMantineTheme } from "@mantine/core";
import { forwardRef } from "react";

const NumberGridLoader = forwardRef<HTMLTableSectionElement | null>((props, ref) => {
  const theme = useMantineTheme();

  return (
    <tbody ref={ref}>
      <tr>
        <td colSpan={4} style={{ padding: theme.spacing.md }}>
          <Loader style={{ display: "block", margin: "0 auto" }} />
        </td>
      </tr>
    </tbody>
  );
})

export default NumberGridLoader;
