/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Number } from './Number';
import {
    NumberFromJSON,
    NumberFromJSONTyped,
    NumberToJSON,
} from './Number';

/**
 *
 * @export
 * @interface NumberList200Response
 */
export interface NumberList200Response {
    /**
     *
     * @type {number}
     * @memberof NumberList200Response
     */
    count: number;
    /**
     *
     * @type {string}
     * @memberof NumberList200Response
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof NumberList200Response
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Number>}
     * @memberof NumberList200Response
     */
    results: Array<Number>;
}

/**
 * Check if a given object implements the NumberList200Response interface.
 */
export function instanceOfNumberList200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function NumberList200ResponseFromJSON(json: any): NumberList200Response {
    return NumberList200ResponseFromJSONTyped(json, false);
}

export function NumberList200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumberList200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(NumberFromJSON)),
    };
}

export function NumberList200ResponseToJSON(value?: NumberList200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(NumberToJSON)),
    };
}
