import { Button, Stack, Title, Text, Box } from "@mantine/core";

function CrashFallback() {
  return (
    <Box maw={500} mx="auto" p="md">
      <Stack>
        <Title order={1} align="center">Номера.биз - Лучшие номера</Title>
        <Text align="center">Произошла ошибка, перезагрузите страницу.</Text>
        <Button onClick={() => window.location.reload()}>Перезагрузить</Button>
      </Stack>
    </Box>
  );
}

export default CrashFallback;
