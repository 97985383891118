import { Button, Group, Text } from "@mantine/core";

interface Props {
  isSelected: boolean,
  onCreateRequest: () => void,
  statusText: string,
}

function Toolbar(props: Props) {
  let button = null;
  if (props.isSelected) {
    button = (
      <Button
        mr="auto"
        onClick={props.onCreateRequest}
        data-cy="activation-request-button"
      >Отправить заявку</Button>
    );
  } else {
    button = (
      <Button
        mr="auto"
        disabled
        data-cy="activation-request-button"
      >Выберите номер</Button>
    );
  }

  return (
    <Group align="center" spacing="xs">
      {button}
      <Text size="sm" color="gray">{props.statusText}</Text>
    </Group>
  );
}

export default Toolbar;
