/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     *
     * @type {number}
     * @memberof Provider
     */
    readonly id?: number;
    /**
     *
     * @type {string}
     * @memberof Provider
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Provider
     */
    displayName: string;
    /**
     * Квадратная, цветная, с прозрачным фоном. Рекомендуемый размер от 48x48 пикселей.
     * @type {string}
     * @memberof Provider
     */
    readonly icon?: string | null;
}

/**
 * Check if a given object implements the Provider interface.
 */
export function instanceOfProvider(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "displayName" in value;

    return isInstance;
}

export function ProviderFromJSON(json: any): Provider {
    return ProviderFromJSONTyped(json, false);
}

export function ProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Provider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'displayName': json['display_name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function ProviderToJSON(value?: Provider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'name': value.name,
        'display_name': value.displayName,
    };
}
