import { useEffect, useMemo, useRef, useState } from "react";
import { ApiRequestState, getApi, handleApiError, handleApiFormError } from "./api";
import isEqual from "lodash/isEqual";
import { UseFormReturnType } from "@mantine/form";
import { MantineSize, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

/**
 * Обертка над useEffect, которая вызывается строго только тогда, когда меняются ее зависимости,
 * даже в strict mode. См. https://github.com/reactwg/react-18/discussions/18
 */
export function useStrictEffect(f: () => void, deps?: any[]) {
  let initRef = useRef(false);
  let depsRef = useRef(deps);

  useEffect(() => {
    if (initRef.current && isEqual(deps, depsRef.current)) {
      return;
    }
    initRef.current = true;
    depsRef.current = deps;

    f();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useApi = () => {
  return useMemo(() => getApi(), []);
}

interface UseApiRequestOptions {
  // Если указана, useApiRequest выведет ошибки валидации полей в форме.
  form?: UseFormReturnType<any>,
}

/**
 * Оборачивает запрос к API, добавляя к нему обработку ошибок и отслеживание состояния.
 * @param request Функция, выполняющая запрос(ы) к API и (опционально) возвращающая его результат.
 * @param options См. UseApiRequestOptions.
 * @returns Два значения:
 *  1. Функцию-обертку над request, которую следует вызывать вместо request.
 *     Обертка может вернуть undefined, если request завершилась ошибкой.
 *  2. Состояние запроса, обновляемое через хук.
 */
export function useApiRequest<Args extends any[], Resp>(request: (...args: Args) => Promise<Resp>, options?: UseApiRequestOptions): [(...args: Args) => Promise<Resp|undefined>, ApiRequestState] {
  let [state, setState] = useState<ApiRequestState>("idle");

  let wrappedRequest = async (...args: Args) => {
    try {
      setState("loading");
      let resp = await request(...args);
      setState("succeeded");
      return resp;
    } catch (e) {
      setState("failed");

      if (options && options.form) {
        handleApiFormError(e, options.form);
      } else {
        handleApiError(e);
      }
    }
  };

  return [wrappedRequest, state];
}

/**
 * Как useMediaQuery, но принимает breakpoint.
 */
export function useSmallerThan(breakpoint: MantineSize): boolean {
  const theme = useMantineTheme();
  return useMediaQuery(theme.fn.smallerThan(breakpoint).replace("@media ", ""));
}
