import { Checkbox, Group, Input, MultiSelect, NumberInput, SegmentedControl, SimpleGrid, Stack, TextInput } from "@mantine/core";
import { Provider, Region, Tag } from "../client";
import { toSelectItems } from "../utils/format";
import ProviderSelectItem from "./ProviderSelectItem";
import ProviderSelectValue from "./ProviderSelectValue";
import { UseFormReturnType, useForm } from "@mantine/form";
import { useCallback } from "react";
import debounce from "lodash/debounce";
import { FilterValues, NumberKind } from "../types/app";
import { useTimeout } from "@mantine/hooks";

const SUBMIT_DEBOUNCE_PAUSE = 500;

interface Props {
  providers: Provider[],
  tags: Tag[],
  regions: Region[],
  values: FilterValues,
  onChange: (values: FilterValues) => void,
}

interface FormValues {
  mask: string,
  maskStart: boolean,
  maskEnd: boolean,
  kind: '' | NumberKind,
  rentPriceFrom: number | undefined,
  rentPriceTo: number | undefined,
  providers: string[],
  tags: string[],
  regions: string[],
}

interface NumberKindSelectItem {
  label: string,
  value: '' | NumberKind,
}

const numberKindSelectItems: NumberKindSelectItem[] = [
  { label: 'Любой', value: '' },
  { label: 'Прямой', value: 'landline' },
  { label: 'Федеральный', value: 'federal' },
];

function Filters(props: Props) {
  const form = useForm<FormValues>({
    initialValues: {
      mask: props.values.mask,
      maskStart: props.values.maskStart,
      maskEnd: props.values.maskEnd,
      kind: props.values.kind || "",
      rentPriceFrom: props.values.rentPriceFrom,
      rentPriceTo: props.values.rentPriceTo,
      providers: props.values.providerIds.map(id => id.toString()),
      tags: props.values.tagIds.map(id => id.toString()),
      regions: props.values.regionIds.map(id => id.toString()),
    },
    validate: {
      mask: (value) => {
        if (value && !/^[0-9a-fx-zA-FX-Z]{0,10}$/.test(value)) {
          return "Маска может содержать только цифры и буквы abcdefxyz.";
        }
      },
    },
  });

  const handleSubmit = (form: UseFormReturnType<FormValues>) => {
    if (form.validate().hasErrors) {
      return;
    }

    props.onChange({
      mask: form.values.mask,
      maskStart: form.values.maskStart,
      maskEnd: form.values.maskEnd,
      kind: form.values.kind || undefined,
      rentPriceFrom: form.values.rentPriceFrom,
      rentPriceTo: form.values.rentPriceTo,
      providerIds: form.values.providers.map(id => Number(id)),
      tagIds: form.values.tags.map(id => Number(id)),
      regionIds: form.values.regions.map(id => Number(id)),
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitDebounced = useCallback(debounce(handleSubmit, SUBMIT_DEBOUNCE_PAUSE), [props.onChange]);
  // Добавляем паузу, что в форме были уже новые значения. На момент обработки ввода они могут быть старыми.
  const {start: handleSubmitPaused} = useTimeout(() => {
    handleSubmitDebounced(form);
  }, 10);

  const handleChange = (inputProps: ReturnType<typeof form.getInputProps>): ReturnType<typeof form.getInputProps> => {
    const onChange = inputProps.onChange as (...args: any) => any;
    const patchedOnChanged = (...args: any): any => {
      handleSubmitPaused();
      return onChange(...args);
    };
    return {
      ...inputProps,
      onChange: patchedOnChanged,
    };
  }

  return (
    <form
      onInput={handleSubmitPaused}
      onChange={handleSubmitPaused}
    >
      <SimpleGrid cols={2} breakpoints={[{maxWidth: "md", cols: 1}]}>
        <Stack>
          <Group align="flex-start">
            <Stack spacing="sm" style={{ flexGrow: "1" }}>
              <TextInput
                label="Маска номера"
                description="xyz обозначают маскируемые цифры"
                placeholder="345xy"
                inputWrapperOrder={['label', 'input', 'description', 'error']}
                maxLength={10}
                data-cy="mask-field"
                {...handleChange(form.getInputProps('mask'))}
              />
              <Group spacing="sm">
                <Checkbox
                  label="В начале"
                  data-cy="mask-start-checkbox"
                  {...handleChange(form.getInputProps('maskStart', { type: "checkbox" }))}
                />
                <Checkbox
                  label="В конце"
                  data-cy="mask-end-checkbox"
                  {...handleChange(form.getInputProps('maskEnd', { type: "checkbox" }))}
                />
              </Group>
            </Stack>
            <Input.Wrapper label="Вид номера">
              <div>
                <SegmentedControl
                  data={numberKindSelectItems}
                  data-cy="kind-select"
                  {...handleChange(form.getInputProps('kind'))}
                />
              </div>
            </Input.Wrapper>
          </Group>
          <Group grow>
            <NumberInput
              label="Аренда от"
              placeholder="0"
              min={0}
              data-cy="rent-price-from-field"
              {...handleChange(form.getInputProps('rentPriceFrom'))}
            />
            <NumberInput
              label="Аренда до"
              placeholder="∞"
              min={0}
              data-cy="rent-price-to-field"
              {...handleChange(form.getInputProps('rentPriceTo'))}
            />
          </Group>
        </Stack>
        <Stack>
          <MultiSelect
            label="Операторы"
            data={toSelectItems(props.providers, "id", "displayName")}
            itemComponent={ProviderSelectItem}
            valueComponent={ProviderSelectValue}
            placeholder="Все"
            searchable
            clearable
            data-cy="providers-select"
            {...handleChange(form.getInputProps('providers'))}
          />
          <MultiSelect
            label="Теги"
            data={toSelectItems(props.tags, "id", "name")}
            placeholder="Все"
            searchable
            clearable
            data-cy="tags-select"
            {...handleChange(form.getInputProps('tags'))}
          />
          <MultiSelect
            label="Регионы"
            data={toSelectItems(props.regions, "id", "displayName")}
            placeholder="Все"
            searchable
            clearable
            data-cy="regions-select"
            {...handleChange(form.getInputProps('regions'))}
          />
        </Stack>
      </SimpleGrid>
    </form>
  );
}

export default Filters;
