import { Box, CloseButton, MultiSelectValueProps } from "@mantine/core";
import ProviderIcon from "./ProviderIcon";
import { Provider } from "../client";

function ProviderSelectValue({
  value,
  label,
  object,
  onRemove,
  classNames,
  ...others
}: MultiSelectValueProps & { value: string, object: Provider }) {
  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
          backgroundColor: theme.white,
          border: `1px solid ${theme.colors.gray[4]}`,
          paddingLeft: "8px",
          borderRadius: theme.radius.sm,
          fontSize: "12px",
        })}
      >
        {object.icon &&
          <Box mr={4}>
            <ProviderIcon provider={object}/>
          </Box>
        }
        <Box sx={{ lineHeight: 1 }}>{label}</Box>
        <CloseButton
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

export default ProviderSelectValue;
